import React from 'react';
import './PurchasePage.css';
import thankYouImage from './assets/thank-you.webp';

const PurchasePage = () => {
  const handlePurchase = () => {
    const leadId = localStorage.getItem('leadId');
    const userAgent = navigator.userAgent;
    const fbp = getCookie('_fbp');
    const fbclid = getQueryParam('fbclid');
    const ipAddress = ''; // Coleta de IP pode ser feita no servidor, se necessário.
    const fbc = getCookie('_fbc'); // Função para obter o fbc do cookie

    // Enviar evento de compra e o evento personalizado PGVWFV5 para o Facebook Pixel
    if (window.fbq) {
      window.fbq('track', 'Purchase', { value: 0, currency: 'USD', leadId });
    }

    // Enviar evento de compra para o servidor
    fetch('https://conversion-api-gules.vercel.app/compra', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        leadId,
        userAgent,
        ipAddress,
        fbc,
        fbclid,
        fbp,
      }),
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }).then(data => {
      console.log('Success:', data);
      window.location.href = 'https://drive.google.com/drive/folders/1iHzInvfYAnxTbM8t0bPD3QxLTqET8on4?usp=drive_link';
    }).catch(error => {
      console.error('Error:', error);
      // Garantir redirecionamento mesmo se houver erro
      window.location.href = 'https://drive.google.com/drive/folders/1iHzInvfYAnxTbM8t0bPD3QxLTqET8on4?usp=drive_link';
    });
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const getQueryParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };

  return (
    <div className="purchase-container">
      <img src={thankYouImage} alt="Thank You" className="thank-you-image" />
      <h1 className="small-heading">Toque no botão abaixo para receber seus conteúdos</h1>
      <p>Para acessar seus conteúdos extras, basta tocar no botão abaixo.</p>
      <button className="pulse-button" onClick={handlePurchase}>Receber conteúdos agora</button>
    </div>
  );
};

export default PurchasePage;

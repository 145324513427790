import React, { useState, useEffect } from 'react';
import './Comments.css';
import userImage1 from './assets/user-image1.webp';
import userImage2 from './assets/user-image2.webp';
import userImage3 from './assets/user-image3.webp';
import userImage4 from './assets/user-image4.webp';
import userImage5 from './assets/user-image5.webp';
import likeIcon from './assets/like-icon.svg';
import heartIcon from './assets/heart-icon.svg';

const Comments = () => {
  const initialComments = [
    {
      id: 1,
      username: 'Ana Maria Oliveira',
      text: 'Camila, seu trabalho é incrível! Sua experiência e dedicação realmente transformam vidas. Obrigada por nos ajudar a recuperar a felicidade nos nossos relacionamentos.',
      timestamp: '46 min',
      likes: 78,
      userImage: userImage1
    },
    {
      id: 2,
      username: 'Luciana Ferreira',
      text: 'Nunca pensei que poderia ser tão feliz novamente. Graças à Camila, consegui reconquistar o amor da minha vida. Sua orientação é realmente valiosa!',
      timestamp: '22 min',
      likes: 55,
      userImage: userImage2
    },
    {
      id: 3,
      username: 'Patrícia Silva',
      text: 'A experiência dessa moça é impressionante. Ela tem um dom especial para ajudar as pessoas a reviverem seus relacionamentos. Obrigada por tudo, Camila!',
      timestamp: '26 min',
      likes: 64,
      userImage: userImage3
    },
    {
      id: 4,
      username: 'Mariana Souza',
      text: 'Confiei nela e foi a melhor decisão que tomei. Ela realmente entende de relacionamentos e me ajudou a reconquistar meu amor. Recomendo a todos!',
      timestamp: '18 min',
      likes: 24,
      userImage: userImage4
    },
    {
      id: 5,
      username: 'Marcinho',
      text: 'O trabalho dela é simplesmente maravilhoso. Não tenho nem palavras para agradecer o que ela fez por mim.',
      timestamp: '1 hora',
      likes: 97,
      userImage: userImage5
    }
  ];

  const loadCommentsFromStorage = () => {
    const storedComments = localStorage.getItem('comments');
    return storedComments ? JSON.parse(storedComments) : initialComments;
  };

  const loadLikesFromStorage = () => {
    const storedLikes = localStorage.getItem('likedComments');
    return storedLikes ? JSON.parse(storedLikes) : [];
  };

  const [comments, setComments] = useState(loadCommentsFromStorage);
  const [likedComments, setLikedComments] = useState(loadLikesFromStorage);

  useEffect(() => {
    saveCommentsToStorage(comments);
  }, [comments]);

  const saveCommentsToStorage = (comments) => {
    localStorage.setItem('comments', JSON.stringify(comments));
  };

  const saveLikesToStorage = (likes) => {
    localStorage.setItem('likedComments', JSON.stringify(likes));
  };

  const handleLike = (commentId) => {
    if (likedComments.includes(commentId)) {
      return;
    }

    const newComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return { ...comment, likes: comment.likes + 1 };
      }
      return comment;
    });

    const newLikedComments = [...likedComments, commentId];

    setComments(newComments);
    setLikedComments(newLikedComments);
    saveCommentsToStorage(newComments);
    saveLikesToStorage(newLikedComments);
  };

  return (
    <div className="comments-container">
      <h3>Mostrando {comments.length} comentários</h3>
      {comments.map((comment) => (
        <div key={comment.id} className="comment-wrapper">
          <img src={comment.userImage} alt="User" className="comment-user-image" />
          <div className="comment">
            <div className="comment-header">
              <strong className="comment-username">{comment.username}</strong>
            </div>
            <div className="comment-content">
              <p>{comment.text}</p>
            </div>
            <div className="comment-footer">
              <div className="comment-actions">
                <button
                  onClick={() => handleLike(comment.id)}
                  className={`like-button ${likedComments.includes(comment.id) ? 'liked' : ''}`}
                  disabled={likedComments.includes(comment.id)}
                >
                  Curtir
                </button>
                <span className="dot"></span>
                <button className="reply-button">
                  Responder
                </button>
                <span className="timestamp">{comment.timestamp}</span>
              </div>
              <div className="likes">
                <div className="like-heart-icons">
                  <img src={likeIcon} alt="Like" className="icon" />
                  <img src={heartIcon} alt="Heart" className="icon" />
                  <span>{comment.likes}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="consultation-call-to-action">
        <h3 className="consultation-title">Faça sua consulta grátis</h3>
        <p className="consultation-subtitle">👇🏻Me chama no whatsapp👇🏻</p>
      </div>
    </div>
  );
};

export default Comments;

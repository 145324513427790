import React, { useEffect } from 'react';
import './Tarot.css';
import logo from './assets/logo.webp';
import minhaFoto from './assets/minha-foto.webp';
import Comments from './Comments';

const Tarot = () => {
  useEffect(() => {
    const leadId = generateUniqueId();
    localStorage.setItem('leadId', leadId);

    // Capturar fbclid e fbp
    const fbclid = getQueryParam('fbclid');
    const fbp = getCookie('_fbp');

    // Enviar eventos para o Facebook Pixel
    if (window.fbq) {
      window.fbq('init', '401281972707734');
      window.fbq('track', 'PageView');
      window.fbq('trackCustom', 'PGVWFV5', { leadId });
    }

    // Coletar user agent e enviar para o servidor
    const userAgent = navigator.userAgent;
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        const ipAddress = data.ip;

        // Enviar dados para o servidor
        fetch('https://conversion-api-gules.vercel.app/collect', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            leadId,
            userAgent,
            ipAddress,
            fbclid,
            fbp,
          }),
        }).then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        }).then(data => {
          console.log('Success:', data);
        }).catch(error => {
          console.error('Error:', error);
        });
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });
  }, []);

  const generateUniqueId = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  const getQueryParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  return (
    <div className="tarot-container">
      <header className="tarot-header">
        <img src={logo} alt="Logo" className="logo" width="200" height="200" />
        <h1>Consulta amorosa grátis</h1>
        <h2>Perita em casos amorosos</h2>
      </header>
      <div className="tarot-content">
        <h3>Atendimento 100% seguro e particular</h3>
        <p>Descubra agora o porque seu amor está distante</p>
      </div>
      <div className="sobre-mim">
        <h2>Sobre Mim</h2>
        <img src={minhaFoto} alt="Minha Foto" className="minha-foto" width="150" height="150" />
        <p>
          Olá, sou a Camila, especialista em relacionamentos amorosos. Ao longo de 7 anos, transformei a vida de milhares de pessoas, ajudando-as a reconquistar seus amores e a reviver seus relacionamentos perdidos.
        </p>
        <p>
          Se você quer trazer de volta a pessoa amada e recuperar a felicidade, eu posso te ajudar. Com minha vasta experiência, você encontrará a solução para o seu caso amoroso. Não espere mais para ser feliz.
        </p>
        <p>
          Confie na minha experiência e permita que eu guie você para um relacionamento pleno e amoroso. Confira abaixo alguns resultados.
        </p>
        <h3>TOQUE NO BOTÃO ABAIXO E FAÇA SUA CONSULTA GRÁTIS</h3>
      </div>
      <Comments />
    </div>
  );
};

export default Tarot;
